@use "sass:math";

// This file recreates the old "blue" theme that was removed in v23, and re-implements it as a custom
// theme extending ag-theme-base.

// ag-grid.scss defines structural styles and should be imported first
@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-base/sass/ag-theme-base.scss';

.ag-theme-blue {
  @include ag-theme-base((
    "foreground-color": #222,
    "background-color": #FFF,
    "odd-row-background-color": #deebf7,
    "input-disabled-background-color": #dedede,
    "border-color": #9bc2e6,
    "cell-horizontal-border": dotted #9bc2e6,
    "header-background-color": #5e9cd3,
    "header-cell-moving-background-color": #9bc2e6,
    "header-foreground-color": #FFF,
    "control-panel-background-color": #f6f6f6,
    "chip-background-color": #ddebf7,
    "range-selection-highlight-color": rgba(#8F8, 0.4),
    "value-change-delta-up-color": darkgreen,
    "value-change-delta-down-color": darkred,
    "value-change-value-highlight-background-color": #cec,
    "input-border-color": #000,
    "header-column-separator": true,
    "row-border-color": null,
    "row-hover-color": #f0f0f0,
    "subheader-background-color": #ececec
  ));

  .ag-icon {
    color: #333;
  }

  .ag-icon-tree-closed::before {
    content: map-get($ag-theme-base-icons-font-codes, "contracted");
  }

  .ag-icon-tree-open::before {
    content: map-get($ag-theme-base-icons-font-codes, "expanded"); 
  }

  .ag-tab {
    border: 1px solid transparent;
    padding: ag-param(grid-size) ag-param(grid-size) * 2;
    margin: ag-param(grid-size);
    margin-bottom: -1px; // shift down 1px to make tab background blend with area below
  }

  .ag-tabs-body {
    margin: (math.div(ag-param(grid-size), 2)) 0;
  }

  .ag-icon {
    margin: 1px 1px 2px 1px;
  }

  .ag-tab-selected {
      background-color: ag-param(background-color);
      border-color: ag-param(border-color);
      border-bottom-color: transparent;
  }

  .ag-tabs-header {
      background-color: ag-param(header-background-color);
      border-bottom: 1px solid ag-param(border-color);
  }

  .ag-filter .ag-filter-apply-panel {
    justify-content: flex-start;
  }

  .ag-menu-option-active {
    background-color: ag-param(selected-row-background-color);
  }

  .ag-column-drop-cell {
    border: 1px solid ag-param(border-color);
    border-radius: 0;
  }

  .ag-column-drop-cell-button .ag-icon {
    border: 1px solid transparent;
  }

  .ag-column-drop-cell-button:hover .ag-icon {
    border-color: ag-param(border-color);
  }

  .ag-panel-title-bar-button-icon {
    font-size: 20px;
    line-height: 20px;
  }

  .ag-menu-option-part {
      padding-top: ag-param(grid-size);
      padding-bottom: ag-param(grid-size);
  }

  .ag-column-drop-vertical-title-bar {
    margin-top: ag-param(grid-size);
    margin-left: ag-param(grid-size);
  }
  .ag-column-drop-vertical-title {
    margin-left: ag-param(grid-size);
  }
  .ag-column-drop-vertical-empty-message {
    margin-left: ag-param(grid-size) * 3 + ag-param(icon-size)
  }
}


body {
  margin: 0;
  padding: 10px;
  font-family: sans-serif;
  letter-spacing: .1px;
}

h1, h2, p, ul, li {
  font-family: sans-serif;
}

.number-cell {
    text-align: right;
}

.centred {
    text-align: center
}

.number-cell-decrease {
   @extend .number-cell;
    color: #FF0000;
}

.number-cell-increase {
  @extend .number-cell;
    color: #008000;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.navbar li {
  float: left;
}

.navbar li a {
  display: block;
  padding: 8px;
  background-color: #dddddd;
}

bg-primary {
    background-color: #ff0000;
}

.active {
  color: #ffffff;
}